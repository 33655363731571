/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useReducer, useMemo, useContext } from 'react';

import {
  Advisor,
  HomeState,
  HomeContextProps,
  HomeProviderProps,
} from '@type/advisor.type';

import { advisorService } from '../services/advisorService';

type Action =
  | { type: 'GET_ADVISORS_REQUEST' }
  | { type: 'GET_ADVISORS_SUCCESS'; payload: Advisor }
  | { type: 'GET_ADVISORS_ERROR'; payload: string };

const initialState: HomeState = {
  advisors: null,
  loading: false,
  error: null,
};

const homePageReducer = (state: HomeState, action: Action): HomeState => {
  switch (action.type) {
    case 'GET_ADVISORS_REQUEST':
      return { ...state, loading: true, error: null };
    case 'GET_ADVISORS_SUCCESS':
      return {
        ...state,
        loading: false,
        advisors: [action.payload],
      };
    case 'GET_ADVISORS_ERROR':
      return { ...state, loading: false, error: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action}`);
  }
};

export const HomeContext = createContext<HomeContextProps | undefined>(
  undefined,
);

export const HomeProvider: React.FC<HomeProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(homePageReducer, initialState);

  const getAdvisor = async (token: string, contactId: string) => {
    dispatch({ type: 'GET_ADVISORS_REQUEST' });
    try {
      const advisor = await advisorService.getClientManager(token, contactId);
      if (advisor) {
        dispatch({ type: 'GET_ADVISORS_SUCCESS', payload: advisor });
      }
      return advisor;
    } catch (error: any) {
      const errorMessage = error.message || 'An error occurred';
      dispatch({ type: 'GET_ADVISORS_ERROR', payload: errorMessage });
    }
  };

  const homeContext = useMemo(
    () => ({
      getAdvisor,
      state,
    }),
    [state],
  );

  return (
    <HomeContext.Provider value={homeContext}>{children}</HomeContext.Provider>
  );
};

export const useHome = () => useContext(HomeContext);

/* eslint-disable react-native/no-color-literals */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@react-native-material/core';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Text, Avatar, ActivityIndicator } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';

import { dashboard } from '@assets/styles/pagesStyle';
import WelcomeUserContainer from '@shared/components/common/LoggedInWelcome';
import PageContainer from '@shared/components/common/PageContainer';
import { Advisor } from '@type/advisor.type';

import Cards from '../components/Cards';
import { useHome } from '../context/HomeContext';

export default function Dashboard() {
  const authContext = useAuth();
  const homeContext = useHome();

  const [loading, setLoading] = useState(true);
  const [advisor, setAdvisor] = useState<Advisor | null | undefined>(null);
  const [messageDisplay, setMessageDisplay] = useState<string>('');
  const [advisorPicture, setAdvisorPicture] = useState<string>('');

  const userToken = authContext.user?.access_token || '';
  let contactId: string;

  const userData = authContext.user?.profile;
  if (userData) {
    contactId = userData.contactId;
  }

  const loadAdvisor = async () => {
    try {
      setLoading(true);

      if (contactId) {
        const assignedAdvisor = await homeContext?.getAdvisor(
          userToken,
          contactId,
        );

        const profileImage = assignedAdvisor
          ? assignedAdvisor.emailAddress.split('@')[0]
          : 'empty_profile';
        setAdvisorPicture(profileImage);

        if (!assignedAdvisor) {
          setMessageDisplay('Unable to load your advisor.');
        }
        setAdvisor(assignedAdvisor);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const advisorImageMap: { [key: string]: any } = {
    anthonyd: require('@assets/salesreps/anthonyd.png'),
    markb: require('@assets/salesreps/markb.png'),
    michaeli: require('@assets/salesreps/michaeli.png'),
    thomast: require('@assets/salesreps/thomast.png'),
  };

  useEffect(() => {
    loadAdvisor();
  }, []);

  return (
    <>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <PageContainer isClient={false}>
          <Box
            id="content"
            style={(dashboard.centeredContainer, styles.boxMarginFlex)}>
            <View style={styles.welcomeHeader}>
              <Box>
                <WelcomeUserContainer />
                <Text variant="titleLarge">Dashboard</Text>
              </Box>
            </View>
            <Cards size="1col">
              <div
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Text variant="titleMedium">Your Advisor</Text>
                {advisor ? (
                  <>
                    <div style={styles.advisorDetails}>
                      <div style={{ padding: 15 }}>
                        <Avatar.Image
                          source={
                            advisorImageMap[advisorPicture] ||
                            require('@assets/salesreps/empty_profile.png')
                          }
                          size={150}
                          style={{
                            borderWidth: '4.8em',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderColor: 'black',
                          }}
                        />
                      </div>

                      <Text
                        variant="titleMedium"
                        style={{ ...styles.text, color: '#2B3674' }}>
                        {`${advisor?.firstName || ''} ${
                          advisor?.lastName || ''
                        }`}
                      </Text>

                      <Text variant="titleMedium" style={styles.text}>
                        {advisor?.emailAddress || messageDisplay}
                      </Text>
                      <Text variant="titleMedium" style={styles.text}>
                        {advisor?.phoneNumber}
                      </Text>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ padding: 15 }}>
                      <Avatar.Image
                        source={require('@assets/salesreps/empty_profile.png')}
                        size={150}
                        style={{
                          borderWidth: '4.8em',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderColor: 'black',
                          borderStyle: 'solid',
                        }}
                      />
                    </div>
                    <Text variant="titleMedium" style={{ color: '#2B3674' }}>
                      {messageDisplay}
                    </Text>
                  </>
                )}
              </div>
            </Cards>
          </Box>
        </PageContainer>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  advisorDetails: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingHorizontal: 15,
    textAlign: 'center',
    width: '100%',
  },
  boxMarginFlex: {
    flex: 1,
    flexWrap: 'wrap',
    margin: 10,
  },
  text: {
    color: '#A3AED0',
    overflow: 'hidden',
    padding: 2,
    textAlign: 'center',
    width: '90%',
  },
  welcomeHeader: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: 10,
    width: '100%',
  },
});

import { config } from '@configs/app.config';

const { endpoint, version } = config.api;

/**
 * Replaces dynamic parameters in the route with actual values.
 * @param route - The route string containing placeholders.
 * @param params - An object with key-value pairs to replace the placeholders.
 * @returns The route with the placeholders replaced by actual values.
 */
export function buildApiRoute(
  route: string,
  params: { [key: string]: string | number },
): string {
  const generateRoute = route.replace(/{(.*?)}/g, (_, key) =>
    params[key].toString(),
  );
  const apiURL = `${endpoint}${generateRoute}`;
  return apiURL;
}

export const API_ROUTES = {
  CLIENT: `core/${version}/client/{clientId}`, //with addresses used in CyberSweep
  CLIENT_PROFILE: `core/${version}/client/{clientId}/profile`,
  CLIENT_FAMILY: `core/${version}/client/{clientId}/family`,
  CLIENT_FAMILY_MEMBER: `core/${version}/client/{clientId}/family/{familyId}`,
  CLIENT_FAMILY_PROFILE: `core/${version}/{familyProfile}`,
  CLIENT_SUPPORT: `core/${version}/client/{clientId}/support`,
  PARTNERS: `core/${version}/partner`,
  PARTNER_DETAILS: `core/${version}/partner/{partnerId}`,
  PARTNER_CONTACTS: `core/${version}/partner/contacts`,
  PARTNER_CONTACT_DETAILS: `core/${version}/partner/contacts/{contactId}`,
  PARTNER_CONTACT_CLIENTS: `core/${version}/partner/contacts/{contactId}/clients`,
  PARTNER_CLIENT_MANAGER: `core/${version}/partner/contacts/{contactId}/clientManager`,
  PARTNER_PRICEBOOK: `core/${version}/partner/{partnerId}/pricebook`,
  PARTNER_REGISTRATIONS: `core/${version}/partner/{partnerId}/registration`,
  VERIFIED_BUSINESS_MANAGER: `core/${version}/verified/businessManager/{contactId}`,
  VERIFIED_CASE: `core/${version}/verified/case`,
  VERIFIED_CASE_CONTACT: `core/${version}/verified/case`,
  CONTROLS_PICKLIST: `core/${version}/controls/list/{object}/{field}`,
  SUPPORT: `core/${version}/client/{clientId}/support`,
  CREDIT_SUMMARY: `creditblock/${version}/bureaus/summary/{clientId}`,
  CREDIT_BLOCK: `creditblock/${version}/bureaus/{bureau}/block`,
  CREDIT_UNBLOCK: `creditblock/${version}/bureaus/{bureau}/unblock`,
};
